<template>
  <device-notification-check></device-notification-check>
  <div class="home-container">
    <div class="home-banner p-4">
      <a-typography-title class="text-white ml-4" :level="2">
        Organization Summary
      </a-typography-title>
    </div>
    <div class="cards-container p-lg-4 p-xl-4 p-md-3 p-sm-4 p-2">
      <div class="cards-wrapper px-lg-4 px-xl-4 px-md-3 px-sm-4 px-2">
        <a-row class="pb-4" :gutter="[24, 24]">
          <a-col
            v-for="device in sortedDevices"
            :key="device.Serial_number"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="12"
            :xl="8"
            :xxl="8"
          >
            <work-station-card
              :device="device"
              :active-devices="activeDevices"
              :id-to-task-name-mapping="idToTaskNameMapping"
              :id-to-device-serial-no-mapping="idToDeviceSerialNoMapping"
              :active-devices-settings="activeDevicesSettings"
              :active-devices-status="activeDevicesStatus"
            />
          </a-col>
        </a-row>
        <div
          v-if="sortedDevices.length === 0"
          style="height: 70vh;"
          class="no-active-device"
        >
          <!-- <a-result status="404" title="No Active Device Found"> </a-result> -->
          <laptop-outlined class="text-secondary" />

          <a-typography-title :level="3" class="mt-2 text-secondary">
            No Active Device(s) Found
          </a-typography-title>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WorkStationCard from './WorkStationCard.vue';
import DeviceService from '@/services/device.js';
import TaskService from '@/services/tasks.js';
import { LaptopOutlined } from '@ant-design/icons-vue';
import { mapGetters } from 'vuex';
import { useActiveDevices } from '../RecordData/composables/useActiveDevices';
import { useDeviceSettings } from '../RecordData/composables/useDeviceSettings';
import { inject, ref, watch } from 'vue';
import DeviceNotificationCheck from 'src/components/user-panel/pages/devices/DeviceNotificationCheck.vue'



export default {
  components: {
    WorkStationCard,
    LaptopOutlined,
    DeviceNotificationCheck
  },

  data() {
    return {
      deviceList: [],
      activeDevices: {},
      isPolled: false,
      activeDevicesPolling: null,
      tasksList: [],
      idToTaskNameMapping: {},
      idToDeviceSerialNoMapping: {},
      activeDevicesSettings: [],
      activeDevicesStatus: {},
    };
  },

   setup() {
    const toast = inject('toast');
    const deviceRef = ref({});
    const {
      activeDevicesList
    } = useActiveDevices();
   
    const {
      devicesSettingsDict,
      devicesStatusDict,
    } = useDeviceSettings(toast);

  watch(
      () => activeDevicesList.value,
      (dict, _) => {
        if (!Object.keys(dict).length) return;
        deviceRef.value = {}
        Object.entries(dict).forEach(([key, value]) => {
          deviceRef.value[value?.Serial_number] = value
        })
      }
    );
    return {
    activeDevicesList,
    devicesSettingsDict,
    devicesStatusDict,
    activeDevices: deviceRef
    };
  },

  computed: {
    ...mapGetters(['organization']),
    sortedDevices() {
      if (this.deviceList.length) {
        const [active, starred, inactive] = this.sortDevices(
          this.deviceList,
          this.sortDevicesCallback
        );
        return [...active, ...starred, ...inactive];
      } else {
        return [];
      }
    },
  },

  watch: {
    deviceList(list) {
      if (!list.length) return;
      this.idToDeviceSerialNoMapping = this.getIdToAttributeMapOfList(
        list,
        'Serial_number'
      );
    },
    tasksList(tasks) {
      if (!tasks.length) return;
      this.idToTaskNameMapping = this.getIdToAttributeMapOfList(
        tasks,
        'taskName'
      );
    },
  },

  created() {
    this.getAllDevices();
    this.getTasksList();
  },

  beforeUnmount() {
    clearInterval(this.activeDevicesPolling);
  },

  methods: {
    sortDevicesCallback(serialNumber, starred) {
      if (
        this.activeDevices &&
        this.activeDevices[serialNumber]
      )
        return 0;    
      if (starred === true) return 1;
      else return 2;
    },

    sortDevices(devices, checkCondition) {
      return devices.reduce(
        (acc, e) => {
          acc[checkCondition(e.Serial_number, e.starred)].push(e);
          return acc;
        },
        [[], [], []]
      );
    },

    getIdToAttributeMapOfList(list, attributeName) {
      return list.reduce((res, el) => {
        res[el.id] = el[attributeName];
        return res;
      }, {});
    },

    async getAllDevices() {
      const [error, data] = await DeviceService.fetchAllDevicesOfOrg(
        this.organization
      );
      if (error) return;
      this.deviceList = data.filter(
        (device) => device.Type !== 'departmental device'
      );
    },

    async getActiveDevices() {
      if (this.isPolled) return;
      this.isPolled = true;
      const [error, data] = await DeviceService.getActiveDevicesOfOrg();
      if (error) return;
      this.isPolled = false;
      this.activeDevices = Array.isArray(data) ? [...data] : Object.keys(data);
    },

    async getTasksList() {
      const [error, data] = await TaskService.fetchTasks(false);
      if (error) return;
      this.tasksList = data;
    },

    async getActiveDevicesSettings() {
      if (!this.activeDevices.length) return;
      const devices = JSON.stringify(this.activeDevices);
      const [error, data] = await DeviceService.getSettingsOfActiveDevices(
        false,
        {
          devices: devices,
        }
      );
      if (error) return;
      this.activeDevicesSettings = data;
      await this.getActiveDevicesStatuses(devices);
    },

    async getActiveDevicesStatuses(devices) {
      const payload = { Serial_number: devices };
      const [error, data] = await DeviceService.getStatusOfActiveDevices(
        false,
        payload
      );
      if (error) return;
      this.activeDevicesStatus = data;
    },
  },
};
</script>
<style>
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-banner {
  width: 100%;
  height: 15vh;
  background-color: #1890ff;
}

.cards-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: translateY(-60px);
  position: relative;
  z-index: 99;
}

.cards-wrapper {
  height: 100%;
  overflow-y: auto;
}

.cards-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.no-active-device {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-active-device > .anticon-laptop {
  font-size: 3em;
}

@media only screen and (max-width: 576px) {
  .cards-container {
    transform: translateY(-40px);
  }
}
</style>
