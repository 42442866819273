<template>
  <div v-if="isLoading" class="video-container">
    <spinner-component />
  </div>
  <video
    v-show="!isLoading"
    id="video"
    class="w-100"
    autoplay
    playsinline
    muted
    controls
    @canplay="setVideoParams"
  />
</template>
<script>
import SpinnerComponent from '../../../shared/Components/Spinner';
import httpClient from '@/service/httpClient';

export default {
  components: { SpinnerComponent },
  props: ['serialNumber'],
  emits: ['closeModal'],
  data() {
    return {
      video: null,
      videoParams: null,
      cssPhysicalProportion: 1,
      videoWidth: 640,
      rtcConfiguration: {
        iceServers: [
          {
            urls: ['turn:3.137.209.16:3478'],
            username: 'retrortc',
            credential: 'retrocausal',
          },
        ],
      },
      deviceMac: null,
      isLoading: true,
      rtcConn: null,
      answerSearching: null,
      iceState: null,
    };
  },
  watch: {
    iceState(value) {
      if (value === 'disconnected') {
        this.$emit('closeModal');
        this.serviceRestart();
      } else if (value === 'connected') {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.deviceMac = this.serialNumber;
  },
  mounted() {
    this.showStream();
    this.video = document.getElementById('video');
  },
  beforeUnmount() {
    clearInterval(this.answerSearching);
  },
  methods: {
    showStream() {
      this.initRTCConnection();
    },

    async initRTCConnection() {
      this.rtcConn = new RTCPeerConnection(this.rtcConfiguration);
      this.rtcConn.addEventListener(
        'iceconnectionstatechange',
        this.setIceState
      );
      this.rtcConn.addEventListener('track', function(evt) {
        if (evt.track.kind === 'video') {
          document.getElementById('video').srcObject = evt.streams[0];
        } else {
          document.getElementById('audio').srcObject = evt.streams[0];
        }
      });

      await this.deleteOffer();
      await this.negotiate();
    },

    setIceState() {
      this.iceState = this.rtcConn.iceConnectionState;
    },

    async negotiate() {
      this.rtcConn.addTransceiver('video', { direction: 'recvonly' });
      const offer = await this.rtcConn.createOffer();
      await this.rtcConn.setLocalDescription(offer);
      await this.gatherIceStates();
      await this.makeOffer();
      this.answerSearching = setInterval(this.waitForAnswer, 1000);
    },

    async gatherIceStates() {
      const pc = this.rtcConn;
      const self = this;
      return new Promise(function(resolve) {
        if (pc.iceGatheringState === 'complete') {
          self.iceState = 'complete';
          resolve();
        } else {
          // eslint-disable-next-line no-inner-declarations
          function checkState() {
            if (pc.iceGatheringState === 'complete') {
              pc.removeEventListener('icegatheringstatechange', checkState);
              resolve();
            }
          }

          pc.addEventListener('icegatheringstatechange', checkState);
        }
      });
    },

    async makeOffer() {
      const offer = this.rtcConn.localDescription;
      const res = await httpClient.post(
        `offer/create_offer/`,
        { device: this.serialNumber, sdp: offer.sdp, type: offer.type },
        false,
        false,
        false
      );
      return res;
    },

    async waitForAnswer() {
      const answer = await httpClient.getData(
        `offer/get_offer/?device=${this.serialNumber}`,
        false,
        false,
        false
      );
      if (answer === 'error') {
        console.log('error waitForAnswer');
        return;
      }
      if (!answer) return;
      if (answer.type === 'offer') return;
      // eslint-disable-next-line no-prototype-builtins
      if (answer.hasOwnProperty('device')) delete answer.device;
      answer['sdp'] += '\r\n';
      await this.rtcConn.setRemoteDescription(answer);
      clearInterval(this.answerSearching);
      this.isLoading = false;
    },

    async deleteOffer() {
      return await httpClient.delete(
        `offer/remove_offer/?device=${this.serialNumber}`,
        '',
        false,
        false,
        false
      );
    },

    async serviceRestart() {
      await this.deleteOffer();
      await this.negotiate();
    },

    setVideoParams() {
      const self = this;
      const modalBody = document.getElementById('stream-modal-body');
      setTimeout(() => {
        const { top, left, width, height } = self.video.getBoundingClientRect();
        const modalParams = modalBody?.getBoundingClientRect();
        self.videoParams = {
          top: Math.floor(top - modalParams.top),
          left: Math.floor(left - modalParams.left),
          width: Math.floor(width),
          height: Math.floor(height),
        };
        self.cssPhysicalProportion = self.videoWidth / width;
      }, 2000);
    },
  },
};
</script>
<style scoped>
.video-container {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
